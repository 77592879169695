<template>
    <div @click="changeRoute" class="route-component">
        <mouse-changer :type="mouseTypes.Explore">
            <div class="image-container">
                <img
                    style="cursor: pointer;"
                    data-scroll
                    data-scroll-id="imageParallax"
                    :src="imagePath"
                    class="image"
                    alt=""
                />
            </div>
            <fancy-text
                :title="routeTitle"
                id="fancy-route-changer"
                :xPercent="0"
                :position-left="true"
                color="#E5B676"
            />
        </mouse-changer>
    </div>
</template>

<script>
import FancyText from "@/components/custom/FancyText";
import MouseChanger from "@/components/custom/MouseChanger";
import { mapGetters } from "vuex";

export default {
    props: {
        background: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: ""
        },
        data: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        FancyText,
        MouseChanger
    },
    computed: {
        ...mapGetters({
            device: "resize/geDevice",
            lang: "language/getLang"
        }),
        imagePath() {
            return this.data?.list?.[0]?.image?.[0]?.devices?.[
                this.device?.type
            ];
        },
        slug() {
            return this.data?.list?.[0]?.slug;
        },
        routeTitle() {
            return this.data?.list?.[0]?.title || this.title;
        }
    },
    methods: {
        changeRoute() {
            window.location = `/${this.lang}/${this.data.list[0].link}`;
        },
        mobileParallax() {
            if (this.device.size < 1024) {
                let images = document.querySelectorAll(
                    ".route-component .image"
                );

                new this.simpleParallax(images, {
                    customContainer: document.querySelector("body"),
                    scale: 1.5,
                    orientation: "down",
                    overflow: true
                });
            }
        }
    },
    created() {
        this.$nextTick(() => {
            this.mobileParallax();
        });
    }
};
</script>

<style lang="scss" scoped>
.route-component /deep/ {
    position: relative;
    height: 376px;

    .image-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 376px;
        cursor: pointer;

        .image {
            height: calc(100% + 50px);
            width: 100%;
            object-fit: cover;
            transform-origin: center;
            top: -25px;
            position: relative;
        }
    }

    .fancy-text {
        top: 100%;

        .title {
            padding-left: 151px;
            @media only screen and (max-width: 1650px) and (min-width: 1440px) {
                padding-left: 62px;
            }
            @media only screen and (max-width: 1439px) and (min-width: 1366px) {
                padding-left: 30px;
            }
            @media only screen and (max-width: 1365px) and (min-width: 768px) {
                padding-left: 30px;
            }
            @media only screen and (max-width: 767px) {
                // padding-left: 14px;
                // text-align: left;
            }
        }
    }

    #fancy-route-changer {
        width: calc(100% - 150px);
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            width: calc(100% - 328px);
        }
        @media only screen and (max-width: 1439px) and (min-width: 1366px) {
            width: calc(100% - 256px);
        }
        @media only screen and (max-width: 1365px) and (min-width: 768px) {
            width: calc(100% - 94px);
        }
        @media only screen and (max-width: 767px) {
            width: calc(100% - 32px);
        }
    }

    @media only screen and (max-width: 767px) {
        height: 202px;

        .image-container {
            height: 202px;
        }
    }
}
</style>
